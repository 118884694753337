<template>
  <b-modal
    id="modal-link-expired"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center">
      <img src="@/assets/link-expired.png" />
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="next"
        variant="warning"
        block
        @click="close"
      >
        Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
  },

  data: () => ({
  }),

  created() {
  },

  methods: {
    close() {
      this.$bvModal.hide('modal-link-expired')
    }
  }
}
</script>
