var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"form-image mx-3"}),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('img',{attrs:{"src":require("@/assets/sidebar-logo.png"),"alt":"logo","width":"200"}})]),(!_vm.isForgot)?_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',[_c('h5',[_vm._v("Login")]),_c('p',{staticClass:"top-text"},[_vm._v(" Please login using your account to start ")])]),_c('b-alert',{attrs:{"variant":"danger","show":!!_vm.error}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('div',[_c('validation-provider',{attrs:{"name":"Email or Username","rules":"required|min:4|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email or Username","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"autocapitalize":"off","placeholder":"Enter your email or username","disabled":_vm.isBusy},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", $$v)},expression:"credentials.username"}})],1)]}}],null,false,3189902531)}),_c('div',{staticClass:"position-relative mb-5"},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:6|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"type":_vm.type,"placeholder":"Enter your password","disabled":_vm.isBusy},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})],1),_c('fa-icon',{staticClass:"icon-hide pointer",attrs:{"icon":"eye","fixed-width":""},on:{"click":_vm.switchVisibility}})]}}],null,false,2986862967)})],1),_c('b-form-group',{staticClass:"pt-4"},[_c('b-button',{staticClass:"next shadow p-2 rounded",attrs:{"type":"submit","variant":"primary","block":"","disabled":!_vm.credentials.username || !_vm.credentials.password || _vm.isBusy}},[_vm._v(" Login "),(_vm.isBusy)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-center mt-2"},[_c('a',{staticClass:"text-capitalize",on:{"click":function($event){_vm.isForgot = !_vm.isForgot}}},[_vm._v("Forgot Password")])])],1):_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.reqResetPassword.apply(null, arguments)}}},[_c('div',[_c('h5',[_vm._v("Reset Password")]),_c('p',{staticClass:"top-text"},[_vm._v(" Enter the email associated with your account ")])]),_c('b-alert',{attrs:{"variant":"danger","show":!!_vm.error}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('div',[_c('validation-provider',{attrs:{"name":"Email or Username","rules":"required|min:4|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email or Username","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"autocapitalize":"off","placeholder":"Enter your email or username","disabled":_vm.isBusy},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", $$v)},expression:"credentials.username"}})],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-center pt-4"},[_c('b-button',{staticClass:"next shadow rounded mr-3",attrs:{"variant":"primary","block":"","disabled":_vm.isBusy},on:{"click":function () {
                _vm.isForgot = !_vm.isForgot
                _vm.error = ''
              }}},[_vm._v(" Back ")]),_c('b-button',{staticClass:"next shadow rounded",attrs:{"type":"submit","block":"","variant":"primary","disabled":_vm.isBusy}},[_vm._v(" Send Email "),(_vm.isBusy)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1)],1)],1)],1)],1)]),_c('send-password'),_c('link-expired')],1)}
var staticRenderFns = []

export { render, staticRenderFns }