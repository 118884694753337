<template>
  <div class="wrapper">
    <div class="form-image mx-3" />
    <validation-observer
      ref="form"
      slim
    >
      <div class="form-container">
        <div class="d-flex justify-content-center mb-4">
          <img
            src="@/assets/sidebar-logo.png"
            alt="logo"
            width="200"
          >
        </div>
        <b-form
          v-if="!isForgot"
          @submit.prevent="login"
          class="d-flex flex-column"
        >
          <div>
            <h5>Login</h5>
            <p class="top-text">
              Please login using your account to start
            </p>
          </div>
          <b-alert
            variant="danger"
            :show="!!error"
          >
            {{ error }}
          </b-alert>
          <div>
            <validation-provider
              name="Email or Username"
              rules="required|min:4|max:25"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Email or Username"
                :invalid-feedback="errors[0]"
              >
                <b-form-input
                  autocapitalize="off"
                  v-model="credentials.username"
                  placeholder="Enter your email or username"
                  :disabled="isBusy"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-form-group>
            </validation-provider>
            <div class="position-relative mb-5">
              <validation-provider
                name="Password"
                rules="required|min:6|max:20"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Password"
                  :invalid-feedback="errors[0]"
                >
                  <b-form-input
                    :type="type"
                    v-model="credentials.password"
                    placeholder="Enter your password"
                    :disabled="isBusy"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
                <fa-icon
                  class="icon-hide pointer"
                  icon="eye"
                  fixed-width
                  @click="switchVisibility"
                />
              </validation-provider>
            </div>
            <b-form-group class="pt-4">
              <b-button
                class="next shadow p-2 rounded"
                type="submit"
                variant="primary"
                block
                :disabled="!credentials.username || !credentials.password || isBusy"
              >
                Login
                <b-spinner
                  v-if="isBusy"
                  class="ml-1"
                  label="Spinning"
                  small
                />
              </b-button>
            </b-form-group>
          </div>
          <div class="text-center mt-2">
            <a
              class="text-capitalize"
              @click="isForgot = !isForgot"
            >Forgot Password</a>
          </div>
        </b-form>
        <b-form
          v-else
          @submit.prevent="reqResetPassword"
          class="d-flex flex-column"
        >
          <div>
            <h5>Reset Password</h5>
            <p class="top-text">
              Enter the email associated with your account
            </p>
          </div>
          <b-alert
            variant="danger"
            :show="!!error"
          >
            {{ error }}
          </b-alert>
          <div>
            <validation-provider
              name="Email or Username"
              rules="required|min:4|max:25"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Email or Username"
                :invalid-feedback="errors[0]"
              >
                <b-form-input
                  autocapitalize="off"
                  v-model="credentials.username"
                  placeholder="Enter your email or username"
                  :disabled="isBusy"
                  :class="{ 'is-invalid': errors.length }"
                />
              </b-form-group>
            </validation-provider>
            <div class="d-flex justify-content-center pt-4">
              <b-button
                class="next shadow rounded mr-3"
                variant="primary"
                block
                :disabled="isBusy"
                @click="() => {
                  isForgot = !isForgot
                  error = ''
                }"
              >
                Back
              </b-button>
              <b-button
                class="next shadow rounded"
                type="submit"
                block
                variant="primary"
                :disabled="isBusy"
              >
                Send Email
                <b-spinner
                  v-if="isBusy"
                  class="ml-1"
                  label="Spinning"
                  small
                />
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </validation-observer>
    <send-password />
    <link-expired />
  </div>
</template>

<script>
import api from '@/api'
import SendPassword from '@/components/SendPassword.vue'
import LinkExpired from '@/components/LinkExpired.vue'

export default {
  components: {
    SendPassword,
    LinkExpired
  },

  data: () => ({
    credentials: {
      username: '',
      password: ''
    },
    confirmPassword: '',
    error: null,
    type: 'password',
    isBusy: false,
    isForgot: false
  }),

  methods: {
    switchVisibility() {
      this.type = this.type === 'password' ? 'text' : 'password'
    },
    async login() {
      try {
        this.isBusy = true

        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        const { data } = await api.auth.login(this.credentials)
        localStorage.setItem('token', data.token)
        localStorage.setItem('refreshToken', data.refreshToken)
        this.fetchProfile()

        this.error = this.$options.data().error

        setTimeout(() => {
          this.$router.push('/dashboard')
        }, 2500)
      } catch (response) {
        this.isBusy = false
        this.error = response.data.messageEng
      }
    },
    async fetchProfile() {
      const { data } = await api.user.me()
      localStorage.setItem('me', JSON.stringify(data))
    },
    async reqResetPassword() {
      try {
        this.isBusy = true

        const valid = await this.$refs.form.validate()
        if (!valid) {
          this.isBusy = false
          return false
        }

        await api.auth.reqResetPassword({ emailOrUser: this.credentials.username })
        this.error = this.$options.data().error

        setTimeout(() => {
          this.$bvModal.show('modal-send-password')
          localStorage.setItem('username', this.credentials.username)
        }, 2500)
        this.isBusy = false
      } catch (response) {
        this.isBusy = false
        this.error = response.data.messageEng
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  min-height: 100%;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.form-container {
  padding-top: 16vh;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media screen and (min-width: 992px) {
    width: 150vw;
  }
}

.form-image {
  align-items: center;
  display: grid;
  justify-content: center;
  min-height: 98vh;
  background-image: url('../assets/login-logo.png');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;

  @media screen and (min-width: 992px) {
    width: 70%;
  }
}

form {
  height: 100%;
  padding-bottom: 10vh;
  margin: 0 15vw 0 15vw;
}

.username {
  background-color: #fff;
  background: url('') no-repeat scroll 7px 5px;
  padding-left: 25px;
  border: 1px solid darkgray;

  @media screen and (min-width: 992px) {
    background-position: 7px 5px;
  }
}

.password {
  background: url('') no-repeat scroll 7px 5px;
  padding-left: 25px;
  border: 1px solid darkgray;

  @media screen and (min-width: 992px) {
    background-position: 7px 5px;
  }
}

.icon-hide {
  color: var(--primary);
  position: absolute;
  right: 10px;
  top: 75%;
  transform: translateY(-65%);
}

.top-text {
  color: #3F3356;
}

.btn-block {
  margin-top: .5rem;
}
</style>
