<template>
  <b-modal
    id="modal-send-password"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center">
      <img src="@/assets/send-password.png" />
    </div>
    <h5 class="font-weight-bold text-center">We have sent a password recover instructions to your email</h5>
    <div class="text-center">
      <span class="text-muted">Did not recieve the email? check your spam filter <br/> or</span>
      <a
        class="text-capitalize"
      >
        Try another email address (30s)
      </a>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="next"
        variant="warning"
        block
        @click="close"
      >
        Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
  },

  data: () => ({
  }),

  created() {
  },

  methods: {
    close() {
      this.$bvModal.hide('modal-send-password')
    }
  }
}
</script>
